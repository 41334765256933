<template>
  <teleport to="body">
    <div v-if="maskDivState.status">
      <div class="maskDiv" v-loading="loadImg.loading" element-loading-background="rgba(0, 0, 0, 0.8)"
        element-loading-text="正在生成报关资料....">
        <div class="closebtn" @click="dialogDetailClass.closeMask">
          <span>x</span>
        </div>
        <div class="displayimage">
          <div class="imgbox">
            <el-image style="width: 100%" :preview-src-list="loadImg.showBigPicBase64"
              :src="loadImg.showBigPicBase64[0]" fit="cover">
              <!-- 加载图片失败提示：无内容 -->
              <template error>
                <div class="image-slot"></div>
              </template>
            </el-image>
          </div>
        </div>
        <div class="displaypic">
          <div class="smallpicbox" v-for="(url, idx) in loadImg.microPicBase64" :key="idx">
            <el-image :class="loadImg.activeIdx == idx ? 'activeImg' : ''" style="height: 100%; max-width: 81px"
              :src="`data:image/jpg;base64,${url}`" fit="fill" :title="loadImg.microImageTitel[idx]"
              @click="dialogDetailClass.currentShowPic(idx)" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
import { reactive, onBeforeMount } from 'vue';
// import { ElMessageBox } from 'element-plus';
import DialogDetailClass from './class/dialogDetailClass';

export default {
  props: ['maskDivStateFather'],
  setup(prop) {
    const loadImg = reactive({
      // 缩略图
      microPicBase64: [],
      // 加载图片数组
      bigPicBase64: [], // 存放下载大图片
      showBigPicBase64: [], // 展示大图片
      microImageTitel: ['合同', '发票', '装箱单', '报关单'],
      activeIdx: 0, // 显示图片索引
      loading: true, // 加载信息
    });

    const maskDivState = reactive(prop.maskDivStateFather);// 开关状态及数据id
    const dialogDetailClass = new DialogDetailClass(loadImg, maskDivState);

    onBeforeMount(async () => {
      // 点击后，加载图片数据
      dialogDetailClass.initLoadImage();
    });

    return {
      dialogDetailClass,
      loadImg,
      maskDivState,
    };
  },
};
</script>
<style lang="scss" scoped>
.maskDiv {
  background: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  color: '';
  min-width: 960px !important;
  $picHeight: 100px;

  .closebtn {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 40px;
    top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba($color: #fff, $alpha: 1);
    border-radius: 50%;
    background: rgba($color: #fff, $alpha: 0.9);
    cursor: pointer;
    transition: ease-in-out 0.3s;

    span {
      color: black;
      display: block;
      font-size: 20px;
    }
  }

  .closebtn:hover {
    background: rgba($color: #fff, $alpha: 0.2);
    border: 1px solid rgba($color: #fff, $alpha: 0.2);

    span {
      color: gray;
    }
  }

  .displayimage {
    width: 100%;
    height: calc(100% - #{$picHeight});
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .imgbox {
      overflow: auto;
      background: white;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .displaypic {
    width: 100%;
    height: $picHeight;
    background: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .smallpicbox {
      height: 90%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: white;
      margin: 0 7px;
      border: 1px solid gray;
      transition: ease-in-out 0.3s;

      :deep(.el-image__inner) {
        width: 100%;
        height: auto;
      }

      .activeImg {
        border: 2px solid $activefontcolor;
        transform: scale(1.04);
      }
    }

    .smallpicbox:hover {
      transform: scale(1.04);
    }
  }
}
</style>
