const stepStatusAry = [
  {
    value: '0',
    label: '全部',
    tagName: '0-全部',
  },
  {
    value: '1',
    label: '待提交',
    tagName: '1-待提交',
  },
  {
    value: '2',
    label: '审核中',
    tagName: '2-审核中',
  },
  {
    value: '3',
    label: '审核不通过',
    tagName: '3-审核不通过',
  },
  {
    value: '4',
    label: '审核通过',
    tagName: '4-审核通过',
  },
  {
    value: '5',
    label: '待委托授权',
    tagName: '5-待委托授权',
  },
  {
    value: '6',
    label: '已支付',
    tagName: '6-已支付',
  },
];
export default {
  stepStatusAry,
};
