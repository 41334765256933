const failedReasonCode = {
  1001: '申报要素填写错误',
  1002: '禁止一般贸易代理出口产品',
  1003: '填写内容逻辑有误',
  1004: '生产厂家内容填写错误',
  1005: '境外收货人内容填写错误',
  1006: '其他错误',
};

/**
 * 获取驳回代码信息
 * @param {String} code 代码字符串
 * @returns {String} 对应描述
 */
function parseFailedCode(code) {
  if (Object.keys(failedReasonCode).includes(code)) {
    return failedReasonCode[code];
  }
  return '';
}
export default {
  parseFailedCode,
};
