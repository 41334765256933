import { ElMessage } from 'element-plus';
import myAxios from '../../../utils/axios/index_v1';

export default class DialogDetailClass {
  constructor(loadImg, maskDivState) {
    this.loadImg = loadImg; // 存放加载图片数据
    this.maskDivState = maskDivState; // 父组件遮罩层数据
  }

  // 关闭当前窗口
  closeMask = () => {
    // 关闭当前展示页
    // 清除加载内容
    this.loadImg.microPicBase64 = [];
    this.loadImg.bigPicBase64 = [];
    // 关闭当前页
    this.maskDivState.status = false;
    this.loadImg.loading = true;
  };

  // 点击小图标事件函数(根据idx加载)
  currentShowPic = async (idx) => {
    if (idx !== undefined && idx <= this.loadImg.microPicBase64.length) {
      // 先判断大图片中是否有值
      const f = this.loadImg.bigPicBase64[idx];
      this.loadImg.showBigPicBasef64 = [];

      if (f === undefined) {
        // 没有数据
        this.loadImg.bigPicBase64[idx] = `data:image/jpg;base64,${this.loadImg.microPicBase64[idx]}`;
        this.loadImg.showBigPicBase64[0] = `data:image/jpg;base64,${this.loadImg.microPicBase64[idx]}`;
      } else {
        // 有数据
        this.loadImg.showBigPicBase64[0] = this.loadImg.bigPicBase64[idx];
      }
      // 点击图片展示图片
      this.loadImg.activeIdx = idx;
      return;
    }
    // 向服务器加载数据（不会执行到此处，因为一次性加载了数据）,向服务器加载数据，根据Id展示数据
    const f = this.loadImg.bigPicBase64[idx];
    this.loadImg.showBigPicBase64 = [];
    if (f === undefined) {
      await this.axiosLoadImage(idx)
        .then((res) => {
          this.loadImg.bigPicBase64[idx] = `data:image/jpg;base64,${res.microPic[idx]}`;
          this.loadImg.showBigPicBase64[0] = `data:image/jpg;base64,${res.microPic[idx]}`;
        })
        .catch((err) => {
          Element.error(err);
        });
    } else {
      // 有数据
      this.loadImg.showBigPicBase64[0] = this.loadImg.bigPicBase64[idx];
    }
    // 点击图片展示图片
    this.loadImg.activeIdx = idx;
  };

  // 通过idx加载指定图片(第一次加载图片)
  initLoadImage = async () => {
    await this.axiosLoadImage(this.loadImg.activeIdx)
      .then((res) => {
        this.loadImg.microPicBase64 = res.microPic;
        this.loadImg.bigPicBase64[0] = `data:image/jpg;base64,${res.microPic[0]}`;
        this.loadImg.showBigPicBase64[0] = `data:image/jpg;base64,${res.microPic[0]}`;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 向服务器加载图片
  axiosLoadImage = (idx) => {
    let mskId = ''; // 要获取当前数据id
    if (Object.prototype.hasOwnProperty.call(this.maskDivState.options, 'id')) {
      mskId = this.maskDivState.options.id.toString();
    } else {
      ElMessage.error('未能获取到数据');
      return false;
    }

    const activeId = idx || 0; // 当前点击图片id 0~3为CONTRACT:0，INVOICE:1，PACKINGLIST:2，CUSTOMS:3

    return new Promise((resolve, reject) => {
      myAxios()
        .initAxios({
          method: 'POST',
          url: '/getpdf2image',
          data: {
            id: mskId, // 数据id
            active_id: activeId, // 当前点击小图id
            is_show_chop: false, // 是否显示公章
            chop_model: 'test', // 公章显示名称（JHR,test)G
          },
        })
        .then((res) => {
          this.loadImg.loading = false;
          resolve(res);
        })
        .catch((err) => {
          this.loadImg.loading = false;
          this.maskDivState.status = false; // 关闭加载报关资料pdf
          ElMessage.error(`加载图片失败:${err.message || '服务器错误'}`);
          this.closeMask();
          if (err.code === 1009) {
            // 请重新登陆
            return;
          }
          reject(err);
        });
    });
  };
}
